<template>
  <div>
    <b-field>
      <b-tag size="is-medium" :type="type">
        {{ status }}
      </b-tag>
    </b-field>
  </div>
</template>

<script>
import { STATUS_LIST } from './../store/constants';

export default {
  props: {
    statusId: Number,
  },
  data: () => ({
    statusOptions: STATUS_LIST,
    status: null,
    type: '',
  }),
  watch: {
    statusId: {
      immediate: true,
      handler(newVal) {
        const option = this.statusOptions.find((s) => s.id === newVal);
        this.status = option.label;
        this.type = option.style;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  display: inline-block;
  margin: 0.75rem;
}
</style>