export default {
  // ANPRS page
  getAnprCameras: state => state.cameras,
  getAnprCamerasLoading: state => state.camerasLoading,

  // ANPR tab
  getAnprCameraRecords: state => state.cameraRecords,
  getAnprCameraRecordsCount: state => state.recordsCount,
  getAnprCameraRecordsLoading: state => state.cameraRecordsLoading,
  getNextAnprCameraRecordsLoading: state => state.nextCameraRecordsLoading,

  getCameraRecordFilters: state => ({
    selectedStatus: state.selectedStatusFilter,
    from: state.fromFilter,
    till: state.tillFilter,
    plate: state.plateFilter,
  }),
  getCameraRecordSorting: state => ({
    order: state.order,
    field: state.field,
  }),

  // Whitelist tab
  getWhitelistedPlatesSearchTerm: state => state.whitelistedPlatesSearchTerm,
  getWhitelistedPlatesLoading: state => state.whitelistedPlatesLoading,
  getWhitelistedPlatesSaving: state => state.whitelistedPlatesSaving,
  getWhitelistedPlates: state =>
    state.whitelistedPlates
      .filter(p => p.plate.includes(state.whitelistedPlatesSearchTerm.toUpperCase()))
      .sort(function(a, b) {
        return a.plate < b.plate ? -1 : a.plate > b.plate ? 1 : 0;
      }),
};
