export default {
    // ANPRS page
    setCamerasLoading: (state, isLoading) => (state.camerasLoading = isLoading),
    setCameras: (state, cameras) => (state.cameras = cameras),
  
    // ANPR page
    setCameraRecordsLoading: (state, isLoading) => (state.cameraRecordsLoading = isLoading),
    setNextCameraRecordsLoading: (state, isLoading) => (state.nextCameraRecordsLoading = isLoading),
    setCameraRecords: (state, { data, last_id, count }) => {
      state.cameraRecords = data;
      state.recordsCount = count;
      state.last_id = last_id;
    },
    pushCameraRecords: (state, { data, last_id, count }) => {
      // to make sure that if the same data is fetched multiple times we do not add them to the records
      if(state.last_id === last_id) return;
      state.cameraRecords.push(...data);
      state.recordsCount = count;
      state.last_id = last_id;
    },
    setCameraRecordsFilters: (state, { selectedStatus, from, till, plate }) => {
      state.selectedStatusFilter = selectedStatus;
      state.fromFilter = from;
      state.tillFilter = till;
      state.plateFilter = plate;
    },
    setCameraRecordsSorting: (state, { order, field }) => {
      state.order = order;
      state.field = field;
    },
  
    // Records
    setRecordProcessing: (state, { recordId, processing }) => {
      const record = state.cameraRecords.find(record => record.id === recordId);
      record.isProcessing = processing;
    },
    setRecordStatus: (state, { recordId, status }) => {
      const record = state.cameraRecords.find(record => record.id === recordId);
      record.status = status;
    },
    removeRecord: (state, { recordId }) => {
      state.cameraRecords = state.cameraRecords.filter(record => record.id !== recordId);
      state.recordsCount -= 1;
    },
    setAllRecordsExported: state => {
      state.cameraRecords.forEach(record => (record.status = 4));
    },
  
    //plates manipulation
    setRecordPlate: (state, { recordId, plate }) => {
      const record = state.cameraRecords.find(record => record.id === recordId);
      record.license_plate = plate;
    },
    revertRecordPlate: (state, { recordId, oldPlate }) => {
      const record = state.cameraRecords.find(record => record.id === recordId);
      record.license_plate = oldPlate;
    },
  
    // Whitelist tab
    setWhitelistedPlatesSearchTerm: (state, term) => (state.whitelistedPlatesSearchTerm = term),
    setWhitelistedPlatesLoading: (state, isLoading) => (state.whitelistedPlatesLoading = isLoading),
    setWhitelistedPlatesSaving: (state, isSaving) => (state.whitelistedPlatesSaving = isSaving),
    setWhitelistedPlates: (state, plates) => (state.whitelistedPlates = plates),
    pushWhitelistedPlates: (state, plates) => state.whitelistedPlates.push(...plates),
    removeWhitelistedPlate: (state, plateId) =>
      (state.whitelistedPlates = state.whitelistedPlates.filter(p => p.id !== plateId)),
  };