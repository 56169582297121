<template>
  <b-table
    :data="data"
    :loading="loading"
    :hoverable="true"
    sort-icon="chevron-up"
    :backend-sorting="true"
    @sort="sortPressed"
  >
    <b-table-column field="plate" label="plate" v-slot="props" sortable>
      <plate-edit
        :license-plate="props.row.license_plate"
        :read-only="props.row.status !== 1"
        :reset="loading"
        @saveLicensePlate="onSaveLicensePlate($event, props.row.id, props.row.camera_id)"
      ></plate-edit>
    </b-table-column>

    <b-table-column field="direction" label="direction" v-slot="props" sortable>
      {{ props.row.direction }}
    </b-table-column>

    <b-table-column field="timestamp" label="timestamp" v-slot="props" sortable>
      <b-tag>{{ formatDateToLocal(props.row.timestamp) }}</b-tag>
    </b-table-column>

    <b-table-column field="plate" label="plate" v-slot="props">
      <image-column
        :src="props.row.image"
      >
      </image-column>
    </b-table-column>

    <b-table-column field="context" label="context" v-slot="props">
      <image-column
        :src="props.row.context"
      >
      </image-column>
    </b-table-column>

    <b-table-column label="Actions" v-slot="props">
      <div v-if="props.row.status === 1">
        <b-button
          class="actionBtn"
          type="is-success"
          :disabled="props.row.isProcessing"
          @click="validateRecord(props.row)"
        >
          Validate
        </b-button>
        <b-button
          class="actionBtn"
          type="is-info"
          :disabled="props.row.isProcessing"
          @click="whitelistRecord(props.row)"
        >
          Whitelist
        </b-button>
        <b-button
          class="actionBtn"
          type="is-danger"
          :disabled="props.row.isProcessing"
          @click="deleteRecord(props.row)"
        >
          Delete
        </b-button>
      </div>
      <div v-else>
        <status-tag :status-id="props.row.status"></status-tag>
      </div>
    </b-table-column>

    <template slot="empty">
      <br />
      <p class="has-text-centered">
        <b-icon icon="emoticon-sad" size="is-large" />
      </p>
      <p class="has-text-centered">No Anprdata found!</p>
      <br />
    </template>
  </b-table>
</template>

<script>
import ImageColumn from './RecordsTabTableImage.vue';
import PlateEdit from './RecordsTabTablePlateEdit.vue';
import StatusTag from './RecordsTabTableTag.vue';

export default {
  components: { ImageColumn, PlateEdit, StatusTag },

  props: {
    data: Array,
    loading: Boolean,
  },

  methods: {
    validateRecord({ camera_id, id }) {
      this.$emit('validateAnprRecord', { cameraId: camera_id, recordId: id });
    },
    whitelistRecord({ camera_id, id }) {
      this.$emit('whitelistAnprRecord', { cameraId: camera_id, recordId: id });
    },
    deleteRecord({ license_plate, camera_id, id }) {
      this.$buefy.dialog.confirm({
        title: 'Delete plate ' + license_plate + ' ?',
        message:
          'Are you sure you want to delete the record with plate <b>' + license_plate + '</b>?',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () =>
          this.$emit('deleteAnprRecord', {
            cameraId: camera_id,
            recordId: id,
          }),
      });
    },

    onSaveLicensePlate(newPlate, recordId, cameraId) {
      this.$emit('saveLicensePlate', {
        cameraId,
        recordId,
        plate: newPlate,
      });
    },

    formatDateToLocal(date) {
      return this.$moment.utc(date).format('DD/MM/YYYY HH:mm:ss z');
    },

    sortPressed(field, order) {
      this.$emit('sort', { field, order });
    },
  },
};
</script>

<style lang="scss" scoped>
.actionBtn {
  width: 100%;
  &:not(last-child) {
    margin-bottom: 1rem;
  }
}
</style>
