import actions from './anpr.actions';
import getters from './anpr.getters';
import mutations from './anpr.mutations';

/* anpr.store.js */
// State object
const state = {
  // state for AnprsPage
  camerasLoading: false,
  cameras: [],

  // state for AnprRecords tab
  cameraRecordsLoading: false,
  nextCameraRecordsLoading: false,
  cameraRecords: [],
  recordsCount: 0,

  // records filters
  selectedStatusFilter: 1,
  fromFilter: null,
  tillFilter: null,
  plateFilter: null,
  // records sorting
  field: null,
  order: null,

  // records pageing
  limit: 20,
  last_id: null,

  // state for whitelist tab
  whitelistedPlatesSearchTerm: '',
  whitelistedPlatesLoading: false,
  whitelistedPlates: [],
  whitelistedPlatesSaving: false,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
