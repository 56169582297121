export const STATUS_LIST = [
  { label: 'All', id: 0, style: '' },
  { label: 'To validate', id: 1, style: '' },
  { label: 'Whitelisted', id: 2, style: 'is-info is-light' },
  { label: 'Validated', id: 3, style: 'is-success is-light' },
  { label: 'Exported', id: 4, style: 'is-warning is-light' },
];

export const EXCEL_EXPORT_TYPE = { id: 1, name: 'excel', type: 'excel', extension: '.xlsx' };
export const EXCELBYDAY_EXPORT_TYPE = { id: 2, name: 'excel/day', type: 'zip', extension: '.zip' };
export const CSV_EXPORT_TYPE_AS_ZIP = { id: 3, name: 'csv', type: 'csv', extension: '.zip' };
export const CEVI_EXPORT_TYPE = { id: 4, name: 'cevi', type: 'cevi', extension: '' };
export const CSV_EXPORT_TYPE = { id: 5, name: 'csv', type: 'csv', extension: '.csv' };
export const INTOUCH_EXPORT_TYPE = { id: 6, name: 'intouch', type: 'intouch', extension: '' };
export const XML_EXPORT_TYPE_AS_ZIP = { id: 7, name: 'xml', type: 'xml', extension: '.zip' };


export const EXPORT_OPTIONS = [
  EXCEL_EXPORT_TYPE,
  EXCELBYDAY_EXPORT_TYPE,
  CSV_EXPORT_TYPE_AS_ZIP,
  CEVI_EXPORT_TYPE,
  INTOUCH_EXPORT_TYPE,
  XML_EXPORT_TYPE_AS_ZIP
];

export const EXPORT_WHITELIST = [
  EXCEL_EXPORT_TYPE,
  CSV_EXPORT_TYPE
];
