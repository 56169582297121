<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Export</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-field label="Export Type">
        <b-select placeholder="Select an export type" v-model="format" :expanded="true">
          <option v-for="item in options" :key="item.id" :value="item">{{ item.name }}</option>
        </b-select>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <b-button label="Close" @click="$emit('close')" />
      <b-button label="Export" type="is-primary" @click="onExport" />
    </footer>
  </div>
</template>

<script>
import { EXPORT_WHITELIST } from './../store/constants.js';

export default {
  data: () => ({
    format: EXPORT_WHITELIST[0],
    options: EXPORT_WHITELIST.slice(),
  }),
  methods: {
    onExport() {
      this.$emit('export', { format: this.format, process: this.process });
      this.$parent.close();
    },
  },
};
</script>
