<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Export</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <b-field label="Export Type">
        <b-select placeholder="Select an export type" v-model="format" :expanded="true">
          <option v-for="item in options" :key="item.id" :value="item">{{ item.name }}</option>
        </b-select>
      </b-field>

      <div>
        <b-checkbox v-model="process" :disabled="!processable"> Mark as exported </b-checkbox>
        <div><i>* downloads can take a while, if no filters are applied</i></div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button label="Close" @click="$emit('close')" />
      <b-button label="Export" type="is-primary" @click="onExport" />
    </footer>
  </div>
</template>

<script>
import { EXPORT_OPTIONS, CEVI_EXPORT_TYPE, INTOUCH_EXPORT_TYPE } from './../store/constants.js';

export default {
  beforeMount() {
    if (this.statusId === 3) this.options = EXPORT_OPTIONS.slice();
    else this.options = EXPORT_OPTIONS.slice().filter((exp) => exp.type !== CEVI_EXPORT_TYPE.type && exp.type !== INTOUCH_EXPORT_TYPE.type);
  },

  props: {
    statusId: Number,
  },

  data: () => ({
    process: false,
    format: EXPORT_OPTIONS[0],
    options: [],
  }),

  methods: {
    onExport() {
      this.$emit('export', { format: this.format, process: this.process });
      this.$parent.close();
    },
  },

  computed: {
    processable: function () {
      return (
        (this.statusId === 2 || this.statusId === 3) && this.format.type !== CEVI_EXPORT_TYPE.type && this.format.type !== INTOUCH_EXPORT_TYPE.type
      );
    },
  },

  watch: {
    format: function (newVal, oldVal) {
      if (newVal.type === CEVI_EXPORT_TYPE.type || newVal.type === INTOUCH_EXPORT_TYPE.type) this.process = true;
      else if (newVal.type !== CEVI_EXPORT_TYPE.type && oldVal.type === CEVI_EXPORT_TYPE.type)
        this.process = false;
      else if (newVal.type !== INTOUCH_EXPORT_TYPE.type && oldVal.type === INTOUCH_EXPORT_TYPE.type)
        this.process = false;
    },

  },
};
</script>
