<template>
  <b-image
    :src="src"
    :lazy="true"
    @click.native="imageModal(src)"
    ratio="3by2"
    style="cursor: pointer; width: 300px"
  ></b-image>
</template>

<script>
export default {
  props: {
    src: String,
  },
  methods: {
    imageModal(path) {
      const h = this.$createElement;
      const vnode = h('p', [h('img', { attrs: { src: path } })]);
      this.$buefy.modal.open({
        content: [vnode],
      });
    },
  },
};
</script>

<style lang="scss">
</style>