<template>
  <div>
    <b-field class="inputPlates" label="Plates in format: BE.1-XXX-111">
      <b-taginput
        ellipsis
        placeholder="Press 'enter' or ',' after every plate..."
        aria-close-label="Delete this plate"
        v-model="newPlates"
        :create-tag="createTag"
      ></b-taginput>
    </b-field>

    <b-field class="item" label="Valid from (utc)">
      <b-datetimepicker
        class="width"
        icon="calendar-today"
        placeholder="Select a time/date..."
        :locale="locale"
        :mobile-native="true"
        :append-to-body="true"
        :max-datetime="till"
        v-model="from"
      >
        <template #left>
          <b-button label="Now" type="is-primary" icon-left="clock" @click="from = new Date()" />
        </template>

        <template #right>
          <b-button
            label="Clear"
            type="is-danger"
            icon-left="close"
            outlined
            @click="from = null"
          />
        </template>
      </b-datetimepicker>
    </b-field>

    <b-field class="item" label="Valid till (utc)">
      <b-datetimepicker
        class="width"
        icon="calendar-today"
        placeholder="Select a time/date..."
        :locale="locale"
        :mobile-native="true"
        :append-to-body="true"
        :min-datetime="from"
        v-model="till"
      >
        <template #left>
          <b-button label="Now" type="is-primary" icon-left="clock" @click="till = new Date()" />
        </template>

        <template #right>
          <b-button
            label="Clear"
            type="is-danger"
            icon-left="close"
            outlined
            @click="till = null"
          />
        </template>
      </b-datetimepicker>
    </b-field>
    <div>
      <b-button type="is-success" :loading="savingPlates" @click="saveNewPlates"> Save </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Locale } from './../../../shared/locales.js';

import CardComponent from './../../CardComponent.vue';

export default {
  components: { CardComponent },

  props: {
    cameraId: Number,
  },

  data: () => ({
    newPlates: [],
    from: null,
    till: null,
    locale: Locale,
  }),

  methods: {
    ...mapActions('anprv2', ['addPlatesToWhitelist']),
    createTag: (tag) => tag.toUpperCase(),

    saveNewPlates() {
      if (!this.newPlates.length) return;
      this.addPlatesToWhitelist({
        cameraId: this.cameraId,
        plates: this.newPlates,
        from: this.from ? this.formatDate(this.from) : '',
        till: this.from ? this.formatDate(this.till) : '',
      }).then((_) => {
        this.newPlates = [];
        this.from = null;
        this.till = null;
      });
    },

    formatDate: (date) => date.toString().substring(0, date.toString().indexOf('(') - 1),
  },

  computed: {
    ...mapGetters('anprv2', {
      savingPlates: 'getWhitelistedPlatesSaving',
    }),
  },
};
</script>

<style lang="scss" scoped>
.inputPlates {
  width: 30rem;
}

.item {
  display: inline-block;
  margin-right: 1rem;

  .width {
    width: 14.5rem;
  }
}
</style>