<template>
    <div class="columns is-multiline">
        <div :class="columns" v-for="project in projects" >
            <div class="card card__one" style="cursor:pointer" @click="$router.push({ name:'project.detail', params: {id: project.id} })">
                <div class="card-content">
                    <div class="media">
                        <div class="media-content">
                            <p class="title is-5">{{ project.name }}</p>
                            <p class="subtitle is-6">{{ project.address }} {{ project.nr }} {{ project.town }}</p>
                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>


</template>

<script>
    export default {
        name: "ProjectItemComponent",
        components: {},
        props: {
            projects: {
                type: Array,
                default: () => {}
            },
            columns: {
                type: String,
                default: "column is-one-third-widescreen is-half-tablet is-half"
            },
            status: {
                type: Boolean,
                default: true
            }
        },

    }
</script>

<style scoped>

</style>