<template>
  <div class="formContainer">
    <b-field class="item" label="Status">
      <b-select v-model="selectedStatusInput" placeholder="Select a status">
        <option v-for="status in statusOptions" :key="status.id" :value="status.id">
          {{ status.label }}
        </option>
      </b-select>
    </b-field>

    <b-field class="item" label="Plate">
      <b-input
        class="width"
        placeholder="Type in a license plate..."
        v-model="plateInput"
      ></b-input>
    </b-field>

    <b-field class="item" label="From (utc)">
      <b-datetimepicker
        class="width"
        icon="calendar-today"
        placeholder="Select a date..."
        :locale="locale"
        :mobile-native="true"
        :max-datetime="tillInput"
        v-model="fromInput"
      >
        <template #left>
          <b-button
            label="Now"
            type="is-primary"
            icon-left="clock"
            @click="fromInput = new Date()"
          />
        </template>

        <template #right>
          <b-button
            label="Clear"
            type="is-danger"
            icon-left="close"
            outlined
            @click="fromInput = null"
          />
        </template>
      </b-datetimepicker>
    </b-field>

    <b-field class="item" label="Till (utc)">
      <b-datetimepicker
        class="width"
        icon="calendar-today"
        placeholder="Select a date..."
        :locale="locale"
        :mobile-native="true"
        :min-datetime="fromInput"
        v-model="tillInput"
      >
        <template #left>
          <b-button
            label="Now"
            type="is-primary"
            icon-left="clock"
            @click="tillInput = new Date()"
          />
        </template>

        <template #right>
          <b-button
            label="Clear"
            type="is-danger"
            icon-left="close"
            outlined
            @click="tillInput = null"
          />
        </template>
      </b-datetimepicker>
    </b-field>

    <b-button type="is-info" class="btn" @click="filter()">Filter</b-button>
  </div>
</template>

<script>
import { Locale } from './../../../shared/locales.js'
import { STATUS_LIST } from './../store/constants';

export default {
  name: 'CaptureFilterComponent',

  data: () => ({
    selectedStatusInput: 0,
    fromInput: null,
    tillInput: null,
    plateInput: null,
    statusOptions: STATUS_LIST,
    locale: Locale
  }),

  props: {
    selectedStatus: Number,
    from: Date,
    till: Date,
    plate: String,
  },

  beforeMount() {
    this.selectedStatusInput = this.selectedStatus;
    this.fromInput = this.from;
    this.tillInput = this.till;
    this.plateInput = this.plate;
  },

  methods: {
    filter() {
      this.$emit('filter', {
        selectedStatus: this.selectedStatusInput,
        from: this.fromInput,
        till: this.tillInput,
        plate: this.plateInput,
      });
    },
  },
};
</script>

<style lang="scss">
.item.field .label {
  margin-bottom: unset;
}
</style>

<style lang="scss" scoped>
.formContainer {
  margin-bottom: 5px;
}

.item {
  display: inline-block;
  margin-right: 1.5rem;

  .datepicker {
    font-size: unset;
  }

  .width {
    width: 215px;
  }
}

.btn {
  vertical-align: bottom;
  margin-bottom: 0.75rem;
}
</style>