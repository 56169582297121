export default {
  // Anprs page
  fetchAnprCameras({ commit }) {
    commit('setCamerasLoading', true);
    this.$axios.get('/api/anprcameras').then(response => {
      commit('setCamerasLoading', false);
      commit('setCameras', response.data.data);
    });
  },

  // Anpr tab
  fetchNewAnprCameraRecords({ commit, state: { limit } }, { cameraId, filters, sorting }) {
    const paging = { limit };
    const params = createAnprParams(filters, sorting, paging);

    commit('setCameraRecordsLoading', true);
    return this.$axios
      .get('/api/cameras/' + cameraId + '/anpr', { params })
      .then(response => {
        const data = response.data.data.map(d => ({
          ...d,
          isProcessing: false,
        }));
        commit('setCameraRecords', {
          data,
          last_id: response.data.last_id,
          count: response.data.count,
        });
        commit('setCameraRecordsFilters', filters);
        commit('setCameraRecordsSorting', sorting);
        commit('setCameraRecordsLoading', false);
      })
      .catch(err => {
        commit('setCameraRecordsLoading', false);
        throw err;
      });
  },
  fetchNextAnprCameraRecords({ commit, state }, { cameraId }) {
    if (state.last_id === -1) return;
    const paging = { limit: state.limit, last_id: state.last_id };
    const filters = {
      selectedStatus: state.selectedStatusFilter,
      from: state.fromFilter,
      till: state.tillFilter,
      plate: state.plateFilter,
    };
    const sorting = { field: state.field, order: state.order };
    const params = createAnprParams(filters, sorting, paging);

    commit('setNextCameraRecordsLoading', true);
    return this.$axios
      .get('/api/cameras/' + cameraId + '/anpr', { params })
      .then(response => {
        const data = response.data.data.map(d => ({
          ...d,
          isProcessing: false,
        }));
        commit('pushCameraRecords', {
          data,
          last_id: response.data.last_id,
          count: response.data.count,
        });
        commit('setNextCameraRecordsLoading', false);
      })
      .catch(err => {
        commit('setNextCameraRecordsLoading', false);
        throw err;
      });
  },

  // camera record actions  VERIFY / WHITELIST / DELETE
  validateAnprCameraRecord({ commit, state }, { cameraId, recordId }) {
    commit('setRecordProcessing', { recordId, processing: true });
    return this.$axios
      .post('/api/cameras/' + cameraId + '/anpr/' + recordId + '/verify')
      .then(_ => {
        // 3 = VALIDATED
        if (state.selectedStatusFilter !== 0) commit('removeRecord', { recordId });
        else {
          commit('setRecordStatus', { recordId, status: 3 });
          commit('setRecordProcessing', { recordId, processing: false });
        }
      })
      .catch(err => {
        commit('setRecordProcessing', { recordId, processing: false });
        const record = state.cameraRecords.find(r => r.id === recordId);
        throw record;
      });
  },
  whitelistAnprCameraRecord({ commit, state }, { cameraId, recordId }) {
    commit('setRecordProcessing', { recordId, processing: true });
    return this.$axios
      .post('/api/cameras/' + cameraId + '/anpr/' + recordId + '/whitelist')
      .then(response => {
        commit('pushWhitelistedPlates', [response.data]);
        // 2 = WHITELISTED
        if (state.selectedStatusFilter !== 0) commit('removeRecord', { recordId });
        else {
          commit('setRecordStatus', { recordId, status: 2 });
          commit('setRecordProcessing', { recordId, processing: false });
        }
      })
      .catch(err => {
        commit('setRecordProcessing', { recordId, processing: false });
        const record = state.cameraRecords.find(r => r.id === recordId);
        throw record;
      });
  },
  deleteAnprCameraRecord({ commit, state }, { cameraId, recordId }) {
    commit('setRecordProcessing', { recordId, processing: true });
    return this.$axios
      .delete('/api/cameras/' + cameraId + '/anpr/' + recordId)
      .then(_ => commit('removeRecord', { recordId }))
      .catch(err => {
        commit('setRecordProcessing', { recordId, processing: false });
        const record = state.cameraRecords.find(r => r.id === recordId);
        throw record;
      });
  },

  saveLicensePlate({ commit, state }, { cameraId, recordId, plate }) {
    const oldPlate = state.cameraRecords.find(r => r.id === recordId).license_plate;
    commit('setRecordPlate', { recordId, plate });
    return this.$axios
      .patch('/api/cameras/' + cameraId + '/anpr/' + recordId, {
        license_plate: plate,
      })
      .catch(err => {
        commit('revertRecordPlate', { recordId, oldPlate });
        throw { oldPlate, newPlate: plate };
      });
  },

  // Whitelist tab
  fetchWhitelistedPlates({ commit }, { cameraId }) {
    commit('setWhitelistedPlatesLoading', true);
    return this.$axios
      .get('/api/cameras/' + cameraId + '/anpr/whitelist')
      .then(response => {
        commit('setWhitelistedPlates', response.data);
        commit('setWhitelistedPlatesLoading', false);
      })
      .catch(err => {
        commit('setWhitelistedPlatesLoading', false);
        throw err;
      });
  },
  addPlatesToWhitelist({ commit }, { cameraId, plates, from, till }) {
    commit('setWhitelistedPlatesSaving', true);
    return this.$axios
      .post('/api/cameras/' + cameraId + '/anpr/whitelist', { plates: plates, from, till })
      .then(response => {
        commit('setWhitelistedPlatesSaving', false);
        commit('pushWhitelistedPlates', response.data.data);
      })
      .catch(err => {
        commit('setWhitelistedPlatesSaving', false);
        throw err;
      });
  },
  removeWhitelistedPlate({ commit, state }, { cameraId, plateId, backtrack }) {
    const plate = state.whitelistedPlates.find(p => p.id === plateId);
    const params = new URLSearchParams();
    params.append('backtrack', backtrack);
    commit('removeWhitelistedPlate', plateId);
    return this.$axios
      .delete('/api/cameras/' + cameraId + '/anpr/whitelist/' + plateId, { params })
      .catch(err => {
        commit('pushWhitelistedPlates', [plate]);
        throw err;
      });
  },
};

const createAnprParams = (filters, sorting, paging) => {
  const params = new URLSearchParams();
  params.append('status', filters.selectedStatus);
  // TODO datetimes should be refactored when there is a consensus on how to handle them
  if (filters.from)
    params.append(
      'from',
      filters.from.toString().substring(0, filters.from.toString().indexOf('(') - 1)
    );
  if (filters.till)
    params.append(
      'till',
      filters.till.toString().substring(0, filters.till.toString().indexOf('(') - 1)
    );
  if (filters.plate) params.append('plate', filters.plate);

  if (sorting.field && sorting.field === 'plate') params.append('field', 'license_plate');
  else if (sorting.field) params.append('field', sorting.field);

  if (sorting.order) params.append('order', sorting.order);

  if (paging.limit) params.append('limit', paging.limit);
  if (paging.last_id) params.append('last_id', paging.last_id);
  return params;
};
