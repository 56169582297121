<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <strong class="modal-card-title">Delete {{ plate }} from whitelist?</strong>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <div>
        Are you sure you want to delete <strong>{{ plate }}</strong> from the whitelist?
      </div>
      <br />
      <b-checkbox v-model="backtrack">
        Revert all the allready whitelisted records back to 'To Validate'.
      </b-checkbox>
    </section>
    <footer class="modal-card-foot">
      <b-button class="is-right" label="Close" @click="$emit('close')" />
      <b-button label="Delete" type="is-danger" @click="onDelete" />
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    plate: String,
  },
  data: () => ({
    backtrack: false,
  }),
  methods: {
    onDelete() {
      this.$emit('delete', this.backtrack);
      this.$parent.close();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>