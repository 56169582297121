<template>
  <div>
    <span style="cursor: pointer" v-if="!isEditing" @dblclick="startEdit">
      {{ licensePlate }}
    </span>
    <div v-if="isEditing">
      <b-input v-model="plate"></b-input>
      <b-button icon-left="floppy" size="is-small" type="is-success" @click="savePlate"></b-button>
      <b-button icon-left="close" size="is-small" type="is-danger" @click="stopEdit"></b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    licensePlate: String,
    readOnly: Boolean,
    reset: Boolean
  },
  data: () => ({
    plate: '',
    isEditing: false,
  }),
  beforeMount() {
    this.plate = this.licensePlate;
  },
  methods: {
    startEdit() {
      if(this.readOnly) return;
      this.plate = this.licensePlate;
      this.isEditing = true;
    },
    savePlate() {
      this.isEditing = false;
      if (this.plate === this.licensePlate) return;
      this.$emit('saveLicensePlate', this.plate);
    },
    stopEdit() {
      this.isEditing = false;
      this.plate = this.licensePlate;
    },
  },
  watch: {
    reset: function(newVal) {
      if(newVal && this.isEditing) this.stopEdit();
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  margin-right: 5px;
  margin-top: 5px;
  float: right;
}
</style>