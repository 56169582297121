<template>
  <div>
    <b-field>
      <b-input
        placeholder="Search plate..."
        type="search"
        icon="magnify"
        class="searchPlates"
        :value="searchTerm"
        @input="$emit('updateSearchTerm', $event)"
      ></b-input>
    </b-field>

    <div>
      <plate-tag
        v-for="plate in plates"
        :key="plate.id"
        :plate="plate"
        @removePlate="onRemoveWhitelistedPlates(plate)"
      ></plate-tag>
    </div>
  </div>
</template>

<script>
import DeletePlateModal from './WhitelistTabPlatesDeleteModal.vue';
import PlateTag from './WhitlelistTabPlateTag';

export default {
  components: { PlateTag },

  props: {
    plates: { type: Array, default: [] },
    searchTerm: { type: String, default: '' },
  },

  methods: {
    onRemoveWhitelistedPlates(plate) {
      this.$buefy.modal.open({
        parent: this,
        component: DeletePlateModal,
        type: 'is-danger',
        props: {
          plate: plate.plate,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          delete: (backtrack) => {
            this.$emit('removeWhitelistedPlate', { plateId: plate.id, backtrack });
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchPlates {
  width: 250px;
  margin-bottom: 1.5rem;
}
</style>