<template>
  <div>
    <log-table-component v-if="this.cameras.filter(c => c.id === this.cameraId)[0]"
                         :project_id="this.cameras.filter(c => c.id === this.cameraId)[0].project.id"
                         :package_id="this.cameras.filter(c => c.id === this.cameraId)[0].package.id"
                         :project_name="this.cameras.filter(c => c.id === this.cameraId)[0].project.name"
                         :package_name="this.cameras.filter(c => c.id === this.cameraId)[0].package.name"

    />

    <card-component class="has-mobile-sort-spaced" title="Add Plates" icon="plus">
      <whitelist-tab-add-plate :cameraId="cameraId"></whitelist-tab-add-plate>
    </card-component>

    <card-component
      class="has-mobile-sort-spaced"
      title="Whitelisted Plates"
      icon="car"
      :has-button-slot="true"
    >
      <div slot="button" class="buttons">
        <b-button type="is-grey" size="is-small" icon-left="download" @click="openModal">
          Export
        </b-button>
      </div>

      <whitelist-tab-plates
        :plates="plates"
        :searchTerm="searchTermPlates"
        @updateSearchTerm="setWhitelistedPlatesSearchTerm"
        @removeWhitelistedPlate="onRemoveWhitelistedPlate"
      ></whitelist-tab-plates>
    </card-component>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

import CardComponent from './../../CardComponent.vue';
import WhitelistTabAddPlate from './WhitelistTabAddPlate.vue';
import WhitelistTabPlates from './WhitelistTabPlates.vue';
import ExportModal from './WhitelistTabExportModal.vue';
import LogTableComponent from "@/components/package/LogListComponent.vue";

export default {
  components: {LogTableComponent, CardComponent, WhitelistTabPlates, WhitelistTabAddPlate},

  data: () => ({cameraId: null, project_id: null}),

  beforeMount() {
    this.cameraId = parseInt(this.$route.params.id);
    this.fetchWhitelistedPlates({cameraId: this.cameraId});
    this.fetchAnprCameras()

  },

  methods: {
    ...mapActions('anprv2', ['fetchWhitelistedPlates', 'removeWhitelistedPlate', 'fetchAnprCameras']),
    ...mapMutations('anprv2', ['setWhitelistedPlatesSearchTerm']),

    onRemoveWhitelistedPlate({plateId, backtrack}) {
      this.removeWhitelistedPlate({
        cameraId: this.cameraId,
        backtrack,
        plateId,
      });
    },

    openModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ExportModal,
        props: {
          isWhitelistExport: true,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          export: ({format}) => this.export({format, searchTerm: this.searchTermPlates}),
        },
      });
    },

    export({format, searchTerm}) {
      const params = new URLSearchParams();
      params.append('type', format.type);
      if (searchTerm) params.append('searchTerm', searchTerm);

      this.$axios
        .get('/api/cameras/' + this.cameraId + '/anpr/whitelist/export', {
          params,
          responseType: 'blob',
        })
        .then((response) => {
          const filename = 'anpr_whitelist' + params.toString() + format.extension;
          this.forceFileDownload(response, filename);
          if (process) this.setAllRecordsExported();
        })
        .catch((err) => {
          this.$buefy.toast.open({
            message: 'Oops, something went wrong with the export',
            type: 'is-danger',
          });
          throw err;
        });
    },

    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
  },

  computed: {
    ...mapGetters('anprv2', {
      searchTermPlates: 'getWhitelistedPlatesSearchTerm',
      platesLoading: 'getWhitelistedPlatesLoading',
      plates: 'getWhitelistedPlates',
      cameras: 'getAnprCameras',
    }),
  },
};
</script>
