<template>
    <card-component class="has-table" title="Recent logs" icon="history" :is-scrollable="false" :has-button-slot="true" :has-footer-slot="true">
        <refresh-button :is-loading="loading" size="is-small" slot="button" @button-click="fetchData"></refresh-button>
<!--        <card-toolbar v-if="this.status.loading" slot="toolbar" class="is-upper" :class="(status.toolbarClass)?status.toolbarClass:null">-->
<!--            <div v-if="status.text" slot="left">{{ status.text }}</div>-->
<!--            <span v-if="(status.label || status.labelIcon)" class="tag" :class="status.labelClass" slot="right">-->
<!--        <b-icon v-if="status.labelIcon" :icon="status.labelIcon" custom-size="default"/>-->
<!--        <span v-if="status.label">{{ status.label }}</span>-->
<!--      </span>-->
<!--        </card-toolbar>-->

        <div class="media-list  ml-2 mr-2">
            <article class="media mb-0 mt-0 pl-2 pr-2 pt-3 pb-3" v-for="log in data" :key="log.id">
                <figure class="media-left" >
                    <b-icon class="pt-5"
                            :icon="log.properties.icon"
                            :type="log.properties.type"
                            size="is-small">
                    </b-icon>
                </figure>
                <div class="media-content">
                    <div class="content">
                        <p>

                            <span class="text is-size-6 ">{{ log.description}}</span>
                            <br>
                            <b-tag v-if="project_id"
                                   class="mr-2"
                                   size="is-small">
                              {{ log.properties.p_name }}
                            </b-tag>

                            <span class="has-text-grey">
                                     <b-icon icon="clock-outline"
                                             type="grey"
                                             size="is-small">
                                </b-icon>
                                <small class="has-text-grey pl-2 pr-4">{{ log.created_at | moment("DD MMM 'YY HH:mm:ss")}}</small>

                            </span>


                            <span v-if="log.user" class="has-text-grey" style="white-space:nowrap;">
                                     <b-icon icon="account-outline"
                                             type="grey"
                                             size="is-small">
                                </b-icon>
                                   {{ log.user.name }}
                            </span>
                        </p>

                    </div>
                </div>

            </article>
            <div v-if="this.data.length === 0" class="py-4">
              <p class="has-text-centered ">
                <b-icon icon="cactus" size="is-large" />
              </p>
              <p class="has-text-centered">No logs yet</p>
            </div>
        </div>

        <a v-if="package_id && this.data.length > 0" class="card-footer-item" slot="footer"
            @click.prevent="$router.push({ name:'package.logs', params: {id: package_id, project_name: project_name, package_name: package_name}})">
            <b-icon icon="eye" custom-size="default"/>
            <span>View all</span>
        </a>
        <a v-if="project_id && this.data.length > 0" class="card-footer-item" slot="footer"
           @click.prevent="$router.push({ name:'project.logs', params: {id: project_id, project_name: project_name}})">
          <b-icon icon="eye" custom-size="default"/>
          <span>View all</span>
        </a>
    </card-component>

</template>

<script>
    import packages from "@/store/modules/packages.store"
    import projects from "@/store/modules/projects.store"
    import CardComponent from "@/components/CardComponent";
    import RefreshButton from '@/components/RefreshButton'


    export default {
        name: "LogTableComponent",
        components: { CardComponent, RefreshButton },
        props: ['package_id', 'project_id', 'package_name', 'project_name'],

        data () {
            return {
                data: [],
                loading: true,
                total: 0,
                page: 1,
                per_page: 8,
                sort_field: "",
                sort_order: "asc",
            }
        },

        created() {
            if(!packages.isRegistered) {
                this.$store.registerModule('packages', packages)
                packages.isRegistered = true
            }
            if(!projects.isRegistered) {
              this.$store.registerModule('projects', projects)
              projects.isRegistered = true
            }
        },

        mounted() {
            this.fetchData()
        },

        methods: {

            fetchData() {
                this.loading = true

                const params = [
                    `page=${this.page}`,
                    `per_page=${this.per_page}`,
                    `sort=${this.sort_order === "asc" ? "" : "-"}${this.sort_field}`,

                ].join('&')

                if(this.package_id) {
                  this.$store.dispatch('packages/fetchPackageLogs', { package_id: this.package_id, params: params})
                      .then(response => {
                        this.data = response.data.data
                        this.total = response.data.meta.total

                        this.loading = false
                      })
                } else if (this.project_id) {
                  this.$store.dispatch('projects/fetchProjectLogs', { project_id: this.project_id, params: params})
                      .then(response => {
                        this.data = response.data.data
                        this.total = response.data.meta.total
                        this.loading = false
                      })
                }

            },
            fetchMore(page) {
                this.page = page
                this.fetchData()
            },
            /*
            * Handle sort event
            */
            onSort(field, order) {
                this.sort_field = field
                this.sort_order = order
                this.fetchData()
            },


        }




    }
</script>

<style scoped>

</style>