<template>
  <section class="section is-main-section">
    <b-tabs v-model="activeTab">
      <b-tab-item label="Anpr records">
        <records-tab :active="activeTab === 0"></records-tab>
      </b-tab-item>

      <b-tab-item label="Whitelist">
        <whitelist-tab></whitelist-tab>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import RecordsTab from '../components/RecordsTab.vue';
import WhitelistTab from '../components/WhitelistTab.vue';

export default {
  name: 'AnprPage',
  components: { RecordsTab, WhitelistTab },

  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>

<style scoped lang="scss">
</style>
